<template>
    <!-- Show the general error message panel -->
    <template v-if="error_messages.general != null">
        <panel>
            <p>{{ error_messages.general }}</p>
        </panel>
    </template>
    <!-- If there is no general error messages,
        show the ticket selection panels
    -->
    <template v-else>
        <!--
            Date selection panel.
            Only show this panel for the product-types 'date' and 'timeslot'.
        -->
        <panel v-if="['date','timeslot'].includes(this.product.availability_type)">
            <!-- Show the date error message if there is any. -->
            <template v-if="error_messages.date != null">
                <div class="js-error">
                    <p class="c-form__message c-form__message--error">
                        {{ translate('js.frontend.product.ticket_select.errors.no_date') }}
                    </p>
                </div>
            </template>
            <!-- If there is no date error messages then show the datepicker. -->
            <template v-else>
                <!-- If there are no dates available show an informing message to the client. -->
                <span>
                    {{ translate('js.frontend.product.ticket_select.choose_date') }}
                </span>
                <!-- Show the datepicker if there is any available date. -->
                <datepicker
                    v-model="form.date"
                    model-type="yyyy-MM-dd"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    prevent-min-max-navigation
                    :min-date="min_date"
                    :max-date="max_date"
                    :allowedDates="dateAvailability()"
                    :class="'u-margin-top-small'"
                    inline
                    @update-month-year="updateMonthYear"
                    autoApply>
                </datepicker>
            </template>
        </panel>
        <!--
            Timeslot selection panel
            Only show this panel for the product-type 'timeslot'
            after a date is selected.
        -->
        <panel v-if="showTimeslotPanel">
            <!-- Show the timeslot error message if there is any. -->
            <template v-if="error_messages.timeslot != null">
                <div class="js-error">
                    <p class="c-form__message c-form__message--error">
                        {{ translate('js.frontend.product.ticket_select.errors.no_date') }}
                    </p>
                </div>
            </template>
            <!-- If there is no timeslot error messages then show the timepicker. -->
            <template v-else>
                <!-- If there are no timeslots available show an informing message to the client. -->
                <span>
                    <template v-if="timeslotAvailability().length == 0">
                        {{ translate('js.frontend.product.ticket_select.no_variants_available') }}
                    </template>
                    <template v-else>
                        {{ translate('js.frontend.product.ticket_select.choose_timeslot', { 'date': formattedLocalizedDate }) }}
                    </template>
                </span>
                <!-- Show the timepicker if there is any available timeslot for this date. -->
                <timepicker v-model="form.timeslot" :timeslots="timeslotAvailability()"></timepicker>
            </template>
        </panel>
        <!--
            Product variants selection panel
            Only show this panel if all conditaions are met:
            - For the product-type period this block always shows.
            - For the product-type date a date should be selected.
            - For the product-type timeslot a date and timeslot should be selected.
        -->
        <panel v-if="showProductVariantsPanel" :title="translate('js.frontend.product.ticket_select.product_variant_label.' + this.product.availability_type, { 'value': selectedDateAndTime })">
            <!-- Show the product-variants error message if there is any. -->
            <template v-if="error_messages.product_variants != null">
                <div class="js-error">
                    <p class="c-form__message c-form__message--error">
                        {{ error_messages.product_variants }}
                    </p>
                </div>
            </template>

            <ticket-select-amount v-model="form.product_variants" :product_variants="productVariantsAvailability()"></ticket-select-amount>
        </panel>
        <panel v-else-if="!showProductVariantsPanel && this.product_variants.length == 0">
            <span>{{ translate('js.frontend.product.ticket_select.no_variants_available') }}</span>
        </panel>
         <!--
            The button to add products to the shopping card.
            Only show this panel if all conditaions are met:
            - For the product-type period this block always shows.
            - For the product-type date a date should be selected.
            - For the product-type timeslot a date and timeslot should be selected.
        -->
        <div v-if="showProductVariantsPanel" class="o-flex o-flex--right">
            <button @click="addToShoppingCart" class="c-button c-button--solid c-button--secondary">
                {{ translate('js.frontend.product.ticket_select.add_to_cart') }}
                <span class="c-button__icon far fa-arrow-right"></span>
            </button>
        </div>
    </template>
</template>
<script>
    import Datepicker           from '@vuepic/vue-datepicker';
    import TimePicker           from './TimePicker';
    import TicketSelectAmount   from './TicketSelectAmount';
    import Panel                from './Panel';
    import { usePage }          from '@inertiajs/vue3';

    export default {
        props: {
            product: Object
        },
        components: {
            'datepicker'           : Datepicker,
            'timepicker'           : TimePicker,
            'ticket-select-amount' : TicketSelectAmount,
            'panel'                : Panel
        },
        data() {
            return {
                date_availability: [],
                product_variants: [],
                form: {
                    timeslot: null,
                    date: null,
                    product_variants: {},
                    product_id: this.product.id,
                },
                date_info: null,
                timeslot_info: null,
                error_messages: {
                    general: null,
                    date: null,
                    timeslot: null,
                    product_variant: null
                }
            }
        },
        watch: {
            /**
             * A watcher on the date input field. The form
             * should reset the selected timeslot when a different
             * date is selected.
             */
            'form.date': function() {
                this.form.timeslot =  null;
                this.date_info = this.date_availability
                    .find(date_info =>
                        date_info.available == true && date_info.date == this.form.date
                    );
            },
            /**
             * A watcher on the form inputs, used to reset
             * all the errors when a change is made in the input.
             */
            form: {
                handler() {
                    this.resetErrors();
                },
                deep: true
            }

        },
        computed: {
            /**
             * Calculated variable to set the first
             * selectable date in the calendar.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return Bool
             */
            min_date: function() {
                return new Date();
            },
            /**
             * Calculated variable to set the last
             * selectable date in the calendar.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return Bool
             */
            max_date: function () {
                return new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 6,
                    new Date().getDate()
                );
            },
            /**
             * Return a readable format of the
             * selected data and time
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return String
             */
            selectedDateAndTime: function() {
                let selected_date_and_time = null;

                if (this.product.availability_type == 'date') {
                    selected_date_and_time = this.formattedLocalizedDate;
                }

                if (this.product.availability_type == 'timeslot') {
                    selected_date_and_time = this.formattedLocalizedDate + ', ' + this.form.timeslot;
                }

                return selected_date_and_time;
            },
            /**
             * Calculated variable to determine it the
             * timeslot selection panel should show.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return Bool
             */
            showTimeslotPanel: function() {
                return this.form.date != null && this.product.availability_type == 'timeslot';
            },
            /**
             * Calculated variable to determine it the
             * product variant selection panel should show.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return Bool
             */
            showProductVariantsPanel: function () {
                return (
                    (this.form.date != null && this.form.timeslot != null && this.product?.availability_type == 'timeslot') 
                    || (this.form.date != null && this.product?.availability_type == 'date') 
                    || (this.product?.availability_type == 'period')
                );
            },
            /**
             * Returns the localized written out selected date
             * (i.e. October 23, 2022 or 23 oktober 2022)
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return string
             */
            formattedLocalizedDate: function() {
                return new Date(this.form.date)
                    .toLocaleDateString(
                        this.internationalLocale,
                        {year: 'numeric', month: 'long', day: 'numeric' }
                    );
            },
            /**
             * Returns the internalionally written locale (i.e. nl-NL & en-US)
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return string
             */
            internationalLocale: function() {
                return Lang.get('js.frontend.international_locale.' + usePage().props.locale);
            },
        },
        mounted: function() {
            // Init: Retrieve the product variants and their availabilty
            this.getProductVariantsAndAvailability();
        },
        methods: {
            /**
             * Returns the available dates
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return Array
             */
            dateAvailability: function() {
                // Construct the default response array.
                let dates = [];

                /**
                 * If the API call returned data and this is a date or
                 * timeslot product-type then add the data to the response array.
                 */
                if (['date','timeslot'].includes(this.product.availability_type)
                    && this.date_availability != []
                ) {
                    /**
                     * Only select the date-objects which are available.
                     * Then obtain the 'date' variable of the object
                     * which is then added to the dates array.
                     */
                    dates = this.date_availability
                        .filter(date_info => date_info.available == true)
                        .map(date_info => date_info.date);

                    /**
                     * It is desired to always have a date preselected.
                     * The assumption is that the dates are ordered by date
                     * from the API call, therefor the first date is selected.
                     */
                    if ((this.form.date == null) && (dates.length > 0)) {
                        this.form.date = dates[0];
                    }
                }
                // Always return a date, otherwise all dates will be considered "available" by the datepicker.
                if(dates.length == 0) {
                    dates[0] = new Date();
                }
                return dates;
            },
            /**
             * Returns the timeslots availablilty for a specific date.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return Array
             */
            timeslotAvailability: function() {
                // Construct the default response array.
                let timeslots = [];

                /**
                 * If the API call returned data and this is a timeslot product-type
                 * and a date is selected then add the data to the response array.
                 */
                if (this.form.date != null
                    && this.date_availability != []
                ) {
                    /**
                     * If the date_info object is found
                     * select the timeslot availability info
                     */
                    if (this.date_info?.timeslot_availability
                        && this.product.availability_type == 'timeslot'
                    ) {
                        timeslots = this.date_info.timeslot_availability;
                    }
                }

                return timeslots;
            },
            /**
             * Returns the product variants and their availability.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return Array
             */
            productVariantsAvailability: function() {
                // Construct the default response array.
                let product_variants = [];
                let product_variant_ids = [];

                /**
                 * If the API call returned data for the product_variants
                 * then add the variants to the product_variants array.
                 *
                 * Depending on some condions the product_variants are made
                 * available for selection.
                 *
                 * - For the product-type period the variants are always available.
                 * - For the product-type date only the variants that are present
                 *   in the product_variant_availability array will be available.
                 * - For the product-type timeslot only the variants that are
                 *   present in the product_variant_availability array of the
                 *   date-object will be available.
                 *
                 * The product_variant_availability is first stored in the
                 * product_variant_ids array and at the end of the function the
                 * availibilty is set accordingly.
                 */
                if (this.product_variants != []) {
                    // Set the product variants as response from the API call.
                    product_variants = this.product_variants;

                    /**
                     * For the product-type date only the variants that are present
                     * in the product_variant_availability
                     * will be made available.
                     */
                    if (this.product?.availability_type == 'date'
                        && this.date_info?.product_variant_availability
                    ) {
                        product_variant_ids = this.date_info.product_variant_availability;
                    }

                    /**
                     * For the product-type timeslot only the variants that are
                     * present in the product_variant_availability array of the
                     * date-object will be available.
                     */
                    if (this.product?.availability_type == 'timeslot'
                        && this.date_info?.timeslot_availability
                    ) {
                        // Select the timeslot-object for the selected date.
                        this.timeslot_info = this.date_info.timeslot_availability
                            .find(timeslot_info =>
                                timeslot_info.available == true && timeslot_info.timeslot == this.form.timeslot
                            );

                        /**
                         * If the timeslot_info object is found
                         * select the product_variant_availability.
                         */
                        if (this.timeslot_info?.product_variant_availability) {
                            product_variant_ids = this.timeslot_info.product_variant_availability;
                        }
                    }

                    /**
                     * Loop through all the product-variants to set available
                     * to either true or false and set the initially
                     * amount of selected tickets to 0.
                     */
                    product_variants.forEach(
                        (product_variant) => {
                            /**
                             * Based on the product_variant_ids from either the date or
                             * timeslot of the product_variant_availability, set the
                             * available variable for a product-varaint.
                             *
                             * If an id is present in the product_variant_ids array
                             * the product-variant is set to available.
                             */
                            if (['date','timeslot'].includes(this.product.availability_type)) {
                                /**
                                 * The check to see if a product-variant is
                                 * available (present in the array).
                                 */
                                if (product_variant_ids.includes(product_variant.id)) {
                                    product_variant['available'] = true;
                                } else {
                                    product_variant['available'] = false;
                                }
                            }
                            /**
                             * If the availablity is false or the amount of
                             * selected tickets is not yet set then set the
                             * selected amount of tickets to 0.
                             */
                            if (product_variant['available'] === false
                                || this.form.product_variants[product_variant.id] == undefined
                            ) {
                                this.form.product_variants[product_variant.id] = 0;
                            }
                        }
                    );
                }
                return product_variants;
            },
            /**
             * When the month year update event is triggered, update the product variants and their availability
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return void
             */
            updateMonthYear: function({ instance, month, year }) {
                this.getProductVariantsAndAvailability(month, year);
            },
            /**
             * Retrieve the product variants available for selection.
             * Based on the product-type the date- and timeslot-availability
             * is requested as well for a specific period.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @param null||Date() month The requested month.
             * @param null||Date() year  The requested year.
             *
             * @return  void
             */
            getProductVariantsAndAvailability: function(
                month = new Date().getMonth(),
                year = new Date().getFullYear()
            ) {
                // Reset all errors messages.
                this.resetErrors();

                // Define the post body.
                let post_body = [];

                // Set the start_date and end_date
                var start_date = new Date(year, month, 1);
                var end_date = new Date(year, month + 1, 0);

                /**
                 * For the date and timeslot product a start_date and end_date
                 * should be added in the post_body.
                 *
                 * The start_date and end_date are based on the selected month
                 * of the calendar. The start_date will contain the first day of
                 * the month and the end_date the last day of the month.
                 *
                 * If we are in the current month (of the current year) the
                 * start_date is set to today.
                 */
                if (this.product.availability_type == 'date' || this.product.availability_type == 'timeslot') {
                    // Set today.
                    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

                    /**
                     * If we are in the current month (of the current year) the
                     * start_date is set to today.
                     *
                     * Formatting is applied, example: 2023-01-20
                     */
                    if (today > start_date) {
                        start_date = this.formatDate(today);
                    } else {
                        start_date = this.formatDate(start_date);
                    }

                    /**
                     * The datepicker could select dates in the past.
                     * If this happen make sure to set the end_date to today
                     * as the API call won't allow for dates in the past.
                     */
                    if (today > end_date) {
                        end_date = this.formatDate(today);
                    } else {
                        end_date = this.formatDate(end_date);
                    }

                    // Set the variable in the post_body.
                    post_body = {
                        start_date,
                        end_date
                    };
                }

                /**
                 * Only perform the API call when the start_date or end_date is
                 * not already present in the response. This is to limit the amount
                 * of request if a user is navigation back and forth in the calendar.
                 */
                if (this.date_availability
                    .find(date_availability =>
                        date_availability.date === start_date || date_availability.date === end_date
                    ) === undefined
                ) {
                    // Define the url
                    let url = '/' + usePage().props.locale + '/api/product/' + this.product.id + '/get-product-variants-and-availability';

                    // Perform the post request.
                    axios.post(url, post_body)
                    .then((response) => {
                        /**
                         * If the repsonse is succesfull and the response_data
                         * object is still empty then set the API call data
                         * to this object.
                         *
                         * If the response_date object is not empty it means
                         * that an API call for another month was already
                         * performed. In this case merge the data.
                         */
                        if (this.date_availability === [] && this.product_variants === []) {
                            this.date_availability = response.data.date_availability;

                            this.date_info = this.date_availability
                                .find(date_info =>
                                    date_info.available == true && date_info.date == this.form.date
                                );

                            this.product_variants = response.data.product_variants;
                        } else {
                            // Merge the product variants.
                            response.data.product_variants.forEach(new_product_variant => {
                                /**
                                 * If the product_variant was found replace it
                                 * otherwise add it.
                                 */
                                let existing_product_variant_index =
                                    this.product_variants
                                        .findIndex(existing_product_variant =>
                                            (existing_product_variant.id === new_product_variant.id)
                                        );
                                if (existing_product_variant_index >= 0) {
                                    this.product_variants[existing_product_variant_index] = new_product_variant;
                                } else {
                                    this.product_variants.push(new_product_variant);
                                }
                            });
                        }
                        if (response.data?.date_availability) {
                            // Merge the date_availability
                            response.data.date_availability.forEach( new_date_availability => {
                                /**
                                 * If the date_availability was found replace it
                                 * otherwise add it.
                                 */

                                let existing_date_availability_index =
                                    this.date_availability
                                        .findIndex( existing_date_availability =>
                                            (existing_date_availability.date === new_date_availability.date)
                                        );
                                if (existing_date_availability_index >= 0) {
                                    this.date_availability[existing_date_availability_index] = new_date_availability;
                                } else {
                                    this.date_availability.push(new_date_availability);
                                }
                            });
                        }
                    })
                    .catch(() => {
                        this.error_messages.general = Lang.get('js.frontend.product.ticket_select.errors.general_error')
                    });
                }
            },
            /**
             * Adds the selected product variants, possibly
             * with a selected date and timeslot, to the shopping cart
             *
             * @author Joost Ligthart <joost@click.nl>
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return  void
             */
            addToShoppingCart: function() {
                // Reset all errors messages.
                this.resetErrors();

                // Define the URL.
                let url = '/' + usePage().props.locale + '/api/checkout/add-to-shopping-cart';

                // Perform the post request.
                axios.post(url, this.form)
                    .then((response) => {
                        /**
                         * If the repsonse is succesfull redirect the user
                         * to the shopping cart screen.
                         */
                        window.location.href = response.data.route;
                    })
                    .catch((errors) => {
                        /**
                         * If an error occured it could be a validation error
                         * which has a status code of 422 or another unkonwn error.
                         *
                         * In case of a validation error set per field the error
                         * messages so they can be displayed in the correct panel.
                         */
                        if (errors.response
                            && errors.response.status == 422
                            && typeof errors.response.data == 'object'
                            && typeof errors.response.data.errors == 'object'
                        ) {
                            // Loop over the data to the set errors message per field.
                            for (const [field, error] of Object.entries(errors.response.data.errors)) {
                                this.flattenErrorArrayToString(error, field);
                            }
                        } else {
                            // Set a general error message if an unknown error occurs.
                            this.error_messages.general = Lang.get('js.frontend.product.ticket_select.errors.general_error');
                        }
                    });
            },
            /**
             * Resets the error messages.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @return  void
             */
            resetErrors() {
                this.error_messages = {
                    general: null,
                    date: null,
                    timeslot: null,
                    product_variants: null
                }
            },
            /**
             * Adds a leading zero to a month or year as
             * the JS variable for a date or year does not have them.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @param String num The number to add a leading zero to.
             *
             * @return  String
             */
            padTo2Digits(num) {
                return num.toString().padStart(2, '0');
            },
            /**
             * Format a JS date variable to format: 2023-01-20
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @param String date The date to format.
             *
             * @return  String
             */
            formatDate(date) {
                return [
                    date.getFullYear(),
                    this.padTo2Digits(date.getMonth() + 1),
                    this.padTo2Digits(date.getDate()),
                ].join('-');
            },
            /**
             * The error messages are returned as array from the API Call
             * Using .toString() transforms the messages to a single string.
             *
             * @author Bart Alewijnse <bart@click.nl>
             *
             * @param Array error The error message array
             * @param String field The field name
             *
             * @return  void
             */
            flattenErrorArrayToString: function(error, field) {
                this.error_messages[field] = error.toString();
            },
        }
    }
</script>